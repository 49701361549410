import { useMemo } from 'react'
import useApi from '@/services/hooks/useApiV2'
import { getAllCampaigns, Campaign } from '@/services/api/campaignV2/campaign'
import useSiteAssets from '../useSiteAssets/useSiteAssets'
import { whiteLabelUtil } from '@/utils'

const useCampaigns = () => {
  const { domain } = useSiteAssets()

  const { data, isLoading, isError } = useApi<Campaign[]>({
    key: `campaigns`,
    request: () => getAllCampaigns(),
  })

  const campaigns = useMemo(
    () => data?.filter((c) => whiteLabelUtil.isAllowed(c, domain)),
    [data, domain]
  )

  return {
    campaigns,
    isLoading,
    isError,
  }
}

export default useCampaigns
